import React, { useEffect, useState } from 'react';
import './App.css';
import heroImage from './images/hero.jpg'; // Import the hero image

function App() {
  const [data, setData] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null); // Track the selected image
  const [showScrollButton, setShowScrollButton] = useState(false); // Track scroll button visibility
  const [isLeftRailVisible, setIsLeftRailVisible] = useState(false); // Track left rail visibility

  useEffect(() => {
    fetch('/data.json')
      .then(response => response.json())
      .then(data => setData(data.items));
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      // Show the button if scrolled down 300px or more
      setShowScrollButton(window.scrollY > 300);
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleImageClick = (image) => {
    setSelectedImage(image); // Set the clicked image
  };

  const closeModal = () => {
    setSelectedImage(null); // Close the modal
  };

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Smooth scroll to top
  };

  const toggleLeftRail = () => {
    setIsLeftRailVisible(!isLeftRailVisible); // Toggle left rail visibility
  };

  return (
    <div className="App">
      <header className="App-header">
        <h1>Transformers Center</h1>
        <p>Site getting a redesign! More on the way...</p>
        <a href="https://old.transformers.center" target="_blank" rel="noopener noreferrer" className="old-site-button">
          Visit Old Site
        </a>
        <img src={heroImage} alt="Transformers Hero" className="hero-image" />
      </header>
      <div className="content">
        {/* Left rail for post links */}
        <aside className={`left-rail ${isLeftRailVisible ? 'visible' : 'hidden'}`}>
          <ul>
            {data.map((item, index) => (
              <li key={index}>
                <a href={`#post-${index}`}>{item.title}</a>
              </li>
            ))}
          </ul>
        </aside>
        <main>
          {data.map((item, index) => (
            <div key={index} id={`post-${index}`} className="item">
              <h2>{item.title}</h2>
              <p>Date: {item.date}</p>
              <p>Figure Name: {item.figureName}</p>
              <div dangerouslySetInnerHTML={{ __html: item.body }} />
              
              {/* Conditionally render YouTube video if URL exists */}
              {item.youtubeUrl && (
                <div className="youtube-container">
                  <a href={item.youtubeUrl} target="_blank" rel="noopener noreferrer">
                    Watch on YouTube
                  </a>
                </div>
              )}
        
              <div className="mosaic">
                {item.images.map((image, idx) => (
                  <img
                    key={idx}
                    src={image}
                    alt={item.figureName}
                    onClick={() => handleImageClick(image)} // Add click handler
                  />
                ))}
              </div>
            </div>
          ))}
        </main>
      </div>
      <footer>
        <p>&copy; 2025 Transformers Center</p>
      </footer>

      {/* Modal for displaying the selected image */}
      {selectedImage && (
        <div className="modal" onClick={closeModal}>
          <div className="modal-content" onClick={(e) => e.stopPropagation()}>
            <img src={selectedImage} alt="Selected" />
            <button className="close-button" onClick={closeModal}>
              Close
            </button>
          </div>
        </div>
      )}

      {/* Floating Scroll-to-Top Button */}
      {showScrollButton && (
        <div className="floating-buttons">
          <button className="scroll-to-top" onClick={scrollToTop}>
            ↑ Top
          </button>
          <button className="toggle-left-rail" onClick={toggleLeftRail}>
            {isLeftRailVisible ? 'Hide Menu' : 'Show Menu'}
          </button>
        </div>
      )}

      {/* Bottom "Hide Menu" Button */}
      {!showScrollButton && (
        <button className="toggle-left-rail bottom" onClick={toggleLeftRail}>
          {isLeftRailVisible ? 'Hide Menu' : 'Show Menu'}
        </button>
      )}
    </div>
  );
}

export default App;
